import React from 'react';
import {FormattedMessage} from 'react-intl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

// UPDATE ME WHEN THE DATES ARE CHANGED (see messages.json > maintenanceNotification.message)
const START_DATE_MAINTENANCE = '2024-09-06';
const END_DATE_MAINTENANCE = '2024-09-23';

const currentDate = new Date();

export const MaintenanceNotification = () => {
  if (currentDate <= new Date(END_DATE_MAINTENANCE) && currentDate >= new Date(START_DATE_MAINTENANCE)) {
    return (
      <Paper
        sx={(theme) => ({
          padding: theme.spacing(1),
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          transition: theme.transitions.create('background-color'),
          backgroundColor: '#e6f2f5',
        })}
      >
        <Grid container>
          <Grid item sx={{textAlign: 'center'}}>
            <FormattedMessage id="maintenanceNotification.message"/>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return null;
};
